<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-line-fill"></i> 生産性レポート</h1>
    </header>

    <section class="section">
        <form class="row mb-3" @submit.prevent="fetch()">
            <div class="col-6">
                <div class="input-group">
                    <form-input-month v-model="month_from" required></form-input-month>
                    <span class="input-group-text">〜</span>
                    <form-input-month v-model="month_to" required></form-input-month>
                </div>
            </div>
            <div class="col-4">
                <form-select v-model="dimension" :options="dimensions" model_type="string"></form-select>
            </div>
            <div class="col-4">
                <button type="submit" class="btn btn-primary">表示</button>
            </div>
        </form>

        <table class="table table-bordered">
            <thead class="table-dark">
                <tr>
                    <th class="text-center"></th>
                    <th class="text-center">売上</th>
                    <th class="text-center">粗利</th>
                    <th class="text-center">稼働時間</th>
                    <th class="text-center">売上生産性</th>
                    <th class="text-center">粗利生産性</th>
                </tr>
            </thead>
            <tbody v-if="!loading">
                <tr v-for="row of data" :key="row.dimension_key ?? 0">
                    <td class="">{{ row.dimension_label ?? '未設定' }}</td>
                    <td class="text-end">{{ $helper.number(row.sales_price) }}</td>
                    <td class="text-end">{{ $helper.number(row.gross_profit) }}</td>
                    <td class="text-end">{{ $helper.clock(row.work_time) }}</td>
                    <td class="text-end">{{ $helper.number(row.sales_price_productivity) }}</td>
                    <td class="text-end">{{ $helper.number(row.gross_profit_productivity) }}</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="i in Array(10)" :key="i">
                    <td class=""><text-loader :width="12"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                </tr>
            </tbody>
            <tfoot v-if="loading">
                <tr>
                    <td>合計</td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                </tr>
            </tfoot>
            <tfoot v-else>
                <tr>
                    <td>合計</td>
                    <td class="text-end">{{ $helper.number(total_sales_price) }}</td>
                    <td class="text-end">{{ $helper.number(total_gross_profit) }}</td>
                    <td class="text-end">{{ $helper.number(total_work_time) }}</td>
                    <td class="text-end">{{ $helper.number(total_sales_price_productivity) }}</td>
                    <td class="text-end">{{ $helper.number(total_gross_profit_productivity) }}</td>
                </tr>
            </tfoot>
        </table>

    </section>
</template>

<script>
import FormInputMonth from '@/components/forms/FormInputMonth';
import FormSelect from '@/components/forms/FormSelect';
import TextLoader from '@/components/tools/TextLoader';
import Productivity from "@/models/entities/stats/productivity";

export default {
    name: 'StatsProductivity',
    components: {
        FormInputMonth,
        FormSelect,
        TextLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            loading: true,
            dimension: 'product',
            data: [],
            dimensions: [
                {value: 'project', label: '案件別'},
                {value: 'category_1', label: '案件分類1別'},
                {value: 'category_2', label: '案件分類2別'},
                {value: 'category_3', label: '案件分類3別'},
                {value: 'category_4', label: '案件分類4別'},
            ],
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.loading = true;
            this.$http.get('/stats/productivity', {params: {
                month_from: this.month_from,
                month_to: this.month_to,
                dimension: this.dimension,
            }})
            .then((response) => {
                this.data = [];
                for (let row of response.data) {
                    this.data.push(new Productivity(row));
                }
                this.loading = false;
            });
        },
    },
    computed: {
        month_from: {
            get() {
                return this.$store.state.condition.month_from;
            },
            set(value) {
                this.$store.commit('condition/setMonthFrom', value);
            },
        },
        month_to: {
            get() {
                return this.$store.state.condition.month_to;
            },
            set(value) {
                this.$store.commit('condition/setMonthTo', value);
            },
        },
        total_sales_price() {
            return this.data.reduce((total, row) => total + row.sales_price, 0);
        },
        total_gross_profit() {
            return this.data.reduce((total, row) => total + row.gross_profit, 0);
        },
        total_work_time() {
            return this.data.reduce((total, row) => total + parseInt(row.work_time), 0);
        },
        total_sales_price_productivity() {
            if (this.total_work_time === 0) {
                return "-";
            } else {
                return Math.floor(this.total_sales_price / this.total_work_time);
            }
        },
        total_gross_profit_productivity() {
            if (this.total_work_time === 0) {
                return "-";
            } else {
                return Math.floor(this.total_gross_profit / this.total_work_time);
            }
        },
    },
}
</script>

<style scoped>
</style>
