/**
 * 生産性集計
 */
import { isBlank } from '@/utilities/typing';

class Productivity {
    dimension_key;
    dimension_label;
    sales_price;
    gross_profit;
    work_time;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.dimension_key = properties.dimension_key;
        this.dimension_label = properties.dimension_label;
        this.sales_price = properties.sales_price;
        this.gross_profit = properties.gross_profit;
        this.work_time = properties.work_time;
    }

    // 売上生産性
    get sales_price_productivity() {
        if (this.work_time == 0) {
            return null;
        } else {
            return Math.floor(this.sales_price / this.work_time * 60);
        }
    }

    // 粗利生産性
    get gross_profit_productivity() {
        if (this.work_time == 0) {
            return null;
        } else {
            return Math.floor(this.gross_profit / this.work_time * 60);
        }
    }
}

export default Productivity;
